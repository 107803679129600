<template>
  <v-layout fill-height>
    <template v-if="hasPdf">
      <PdfViewer
        :url="pdfUrl"
        :showControl="showControl"
        :showPageInfo="true"
        v-model="pageNumber"
        :key="pdfUrl"
      />
      <DownloadPdf class="mx-3 my-2 absolute bottom-0 left-0" />
    </template>
    <Slide v-else :mission="mission" />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex"
import SlideService from "@/services/slide.service"

import Slide from "./Slide.vue"
import PdfViewer from "./PdfViewer.vue"

export default {
  name: "SlideWrapper",
  components: {
    DownloadPdf: () =>
      import(
        "@/components/GroupTeams/Common/Games/GameCardParts/DownloadPdf.vue"
      ),
    Slide,
    PdfViewer
  },
  computed: {
    ...mapGetters({
      mission: "getCurrentMission"
    }),
    ...mapGetters(["gameID", "gameStatus"]),
    ...mapGetters("auth", ["isHost", "user"]),
    ...mapGetters("Games", ["meetingPresenter"]),
    hasPdf() {
      return SlideService.hasPdf(this.mission)
    },
    pageNumber: {
      get() {
        return this.gameStatus.slidesIndex || 1
      },
      async set(page) {
        if (!page) return
        await SlideService.updatePage(this.gameID, page)
      }
    },
    showControl() {
      return (
        this.isHost ||
        (this.meetingPresenter && this.meetingPresenter.id === this.user.id)
      )
    },
    pdfUrl() {
      return this.mission.documentUrl
    }
  }
}
</script>
