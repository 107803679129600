var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "fill-height": "" } },
    [
      _vm.hasPdf
        ? [
            _c("PdfViewer", {
              key: _vm.pdfUrl,
              attrs: {
                url: _vm.pdfUrl,
                showControl: _vm.showControl,
                showPageInfo: true,
              },
              model: {
                value: _vm.pageNumber,
                callback: function ($$v) {
                  _vm.pageNumber = $$v
                },
                expression: "pageNumber",
              },
            }),
            _c("DownloadPdf", {
              staticClass: "mx-3 my-2 absolute bottom-0 left-0",
            }),
          ]
        : _c("Slide", { attrs: { mission: _vm.mission } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }