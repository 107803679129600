<template>
  <div class="relative grow">
    <GameCountdown v-if="isAutopilotOn" :endTime="endTime" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { KEY_UP, KEY_DOWN } from "keycode-js"

import GameCountdown from "@/components/GroupTeams/Common/GameCountdown.vue"

import Slide from "@/services/slide.service"

export default {
  name: "Slides",
  components: {
    GameCountdown
  },
  props: {
    mission: Object
  },
  created() {
    window.addEventListener("keyup", this.onKeyUp)
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.onKeyUp)
  },
  computed: {
    ...mapGetters(["game", "gameID", "gameStatus", "isAutopilotOn"]),
    ...mapGetters("auth", ["isHost"]),
    currentSlideIndex() {
      return parseInt(this.gameStatus?.slidesIndex || 1) || 1
    },
    totalSlidesCount() {
      return (this.mission?.representImages || []).length
    },
    endTime() {
      const int = parseInt(this.gameStatus?.endTime) || 0
      const endTime = int - Date.now()
      return endTime > 0 ? endTime : 0
    }
  },
  methods: {
    onKeyUp(e) {
      if (!this.game.isPlayerAdvance && !this.isHost) return
      if (e.keyCode == KEY_UP) return this.nextSlide()
      if (e.keyCode == KEY_DOWN) return this.previousSlide()
    },
    async nextSlide() {
      if (this.totalSlidesCount <= this.currentSlideIndex) return
      await Slide.updatePage(this.gameID, this.currentSlideIndex + 1)
    },
    async previousSlide() {
      if (this.currentSlideIndex)
        await Slide.updatePage(this.gameID, this.currentSlideIndex - 1)
    }
  }
}
</script>
