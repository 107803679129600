var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "pdf-viewer-container", attrs: { column: "" } },
    [
      _c(
        "v-layout",
        {
          staticClass: "mb-1 mt-1 pdf-viewer-container__nav-container",
          attrs: { shrink: "", row: "" },
        },
        [
          _vm.showPageInfo
            ? _c("v-flex", { staticClass: "pdf-viewer-container__page-info" }, [
                _c("span", [
                  _vm._v(
                    " page " +
                      _vm._s(_vm.currentPage) +
                      " / " +
                      _vm._s(_vm.totalPage) +
                      " "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.showControl
            ? _c(
                "v-flex",
                { staticClass: "pdf-viewer-container__goto shrink" },
                [
                  _c("WeveSelectField", {
                    staticStyle: { "min-width": "80px" },
                    attrs: {
                      options: _vm.pages,
                      disabled: _vm.isPdfProcessing,
                      label: "Page",
                      "hide-label": "",
                    },
                    model: {
                      value: _vm.gotoPage,
                      callback: function ($$v) {
                        _vm.gotoPage = $$v
                      },
                      expression: "gotoPage",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showControl
            ? _c(
                "v-flex",
                [
                  _c(
                    "WeveButton",
                    {
                      staticClass: "mr-1",
                      attrs: {
                        disabled: _vm.isFistPage || _vm.isPdfProcessing,
                        size: "sm",
                      },
                      on: { click: _vm.onPrevPage },
                    },
                    [_vm._v(" Back ")]
                  ),
                  _c(
                    "WeveButton",
                    {
                      attrs: {
                        size: "sm",
                        disabled: _vm.isLastPage || _vm.isPdfProcessing,
                      },
                      on: { click: _vm.onNextPage },
                    },
                    [_vm._v(" Next ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-flex",
        { staticStyle: { position: "relative" }, attrs: { grow: "" } },
        [
          _vm.isLoading
            ? _c(
                "div",
                { staticClass: "pdf-viewer-container__loading" },
                [_c("rtb-spinner")],
                1
              )
            : _vm._e(),
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isLoading,
                expression: "!isLoading",
              },
            ],
            ref: "canvas",
            staticClass: "pdf-viewer-container__canvas",
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }