<template>
  <v-layout column class="pdf-viewer-container">
    <v-layout shrink row class="mb-1 mt-1 pdf-viewer-container__nav-container">
      <v-flex v-if="showPageInfo" class="pdf-viewer-container__page-info">
        <span> page {{ currentPage }} / {{ totalPage }} </span>
      </v-flex>
      <v-flex v-if="showControl" class="pdf-viewer-container__goto shrink">
        <WeveSelectField
          style="min-width: 80px"
          :options="pages"
          :disabled="isPdfProcessing"
          label="Page"
          hide-label
          v-model="gotoPage"
        />
      </v-flex>

      <v-flex v-if="showControl">
        <WeveButton
          @click="onPrevPage"
          :disabled="isFistPage || isPdfProcessing"
          class="mr-1"
          size="sm"
        >
          Back
        </WeveButton>
        <WeveButton
          size="sm"
          @click="onNextPage"
          :disabled="isLastPage || isPdfProcessing"
        >
          Next
        </WeveButton>
      </v-flex>
    </v-layout>
    <v-flex grow style="position: relative">
      <div class="pdf-viewer-container__loading" v-if="isLoading">
        <rtb-spinner />
      </div>
      <canvas
        ref="canvas"
        class="pdf-viewer-container__canvas"
        v-show="!isLoading"
      ></canvas>
    </v-flex>
  </v-layout>
</template>

<script>
import { WeveButton, WeveSelectField } from "@weve/ui"
import { debounce } from "lodash"
import { RtbSpinner } from "@/components/ui"

/**
 * https://github.com/mozilla/pdf.js/issues/13200
 */
import * as pdfjs from "pdfjs-dist/legacy/build/pdf"
import PDFJSWorker from "pdfjs-dist/legacy/build/pdf.worker.entry"
pdfjs.GlobalWorkerOptions.workerSrc = PDFJSWorker

export default {
  name: "PdfViewer",
  model: {
    prop: "page",
    event: "input"
  },
  components: {
    WeveButton,
    RtbSpinner,
    WeveSelectField
  },
  data() {
    return {
      state: null,
      isLoading: false,
      isLoading: false,
      isGettingPage: false,
      currentPage: null,
      totalPage: null,
      scale: 1
    }
  },
  props: {
    url: {
      required: true,
      type: String
    },
    showControl: {
      type: Boolean,
      default: false
    },
    showPageInfo: {
      type: Boolean,
      default: true
    },
    page: {
      type: Number,
      default: 1
    }
  },
  watch: {
    page: {
      immediate: false,
      handler: debounce(async function (page) {
        if (page && page === this.currentPage) {
          return console.log("aborted rendering")
        }
        if (!page) page = 1
        await this.renderPage(page)
      }, 800)
    }
  },
  beforeDestroy() {
    this.unmountPdf()
  },
  async created() {
    if (this.url) {
      await this.$nextTick()
      this._currentCachedPage = null
      this._canvas = this.$refs.canvas
      this._canvasCtx = this._canvas.getContext("2d")
      this._pdf = null
      this.mountPdf()
    }
  },
  computed: {
    isFistPage() {
      return this.currentPage === 1
    },
    isLastPage() {
      return this.currentPage === this.totalPage
    },
    isPdfProcessing() {
      return Boolean(this.isGettingPage || this.isLoading)
    },
    pages() {
      const totalPage = this.totalPage ?? 0
      return Array.from({ length: totalPage }, (_, i) => i + 1).map(num => ({
        value: num,
        label: num
      }))
    },
    gotoPage: {
      get() {
        return this.currentPage ?? 1
      },
      async set(page) {
        if (!page) return
        this.onPageChange(page)
      }
    }
  },
  methods: {
    onPrevPage: debounce(function () {
      const prevPage = this.currentPage - 1
      this.onPageChange(prevPage)
    }, 700),
    onNextPage: debounce(function () {
      this.isGettingPage = true
      const nextPage = (this.currentPage ?? 1) + 1
      this.onPageChange(nextPage)
    }, 700),
    async mountPdf() {
      this.isLoading = true
      this._documentDestroyed = false
      this._pdf = await pdfjs.getDocument(this.url).promise
      this.isLoading = false
      this.totalPage = this._pdf.numPages
      await this.renderPage(this.page ?? 1)
    },
    async unmountPdf() {
      if (!this._pdf) return

      try {
        await this._pdf.destroy()
        await this._pdf.cleanup()
      } catch (e) {
        console.log(e)
      }

      this._pdf = this._currentCachedPage = undefined
    },
    onPageChange(page) {
      this.$emit("input", page)
    },
    async getPage(pageNumber) {
      if (pageNumber < 1) pageNumber = 1

      this._currentCachedPage = await this._pdf.getPage(pageNumber)
      this.currentPage = pageNumber
      return this._currentCachedPage
    },
    async renderPdf() {
      if (!this._currentCachedPage) throw Error("No PDF data found")

      const viewport = this._currentCachedPage.getViewport({
        scale: this.scale
      })

      this._canvas.height = viewport.height
      this._canvas.width = viewport.width

      const renderContext = {
        canvasContext: this._canvasCtx,
        viewport: viewport
      }

      if (this._lastRenderTask) {
        this._lastRenderTask.cancel()
      }

      this._lastRenderTask = this._currentCachedPage.render(renderContext)

      return await this._lastRenderTask.promise
    },
    async renderPage(pageNumber) {
      if (pageNumber === this._currentCachedPage?.pageNumber) {
        return console.warn(
          "PDF:renderPage skipped rendering same page or last requested page is still processing..."
        )
      }
      this.isGettingPage = true
      await this.getPage(pageNumber)
      await this.renderPdf()
      this.isGettingPage = false
    }
  }
}
</script>

<style lang="scss">
.pdf-viewer-container {
  height: 100%;
  &__canvas {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  &__page-info {
    justify-content: center;
    display: flex;
    align-items: center;
    color: white;
  }
  &__goto {
    .weve-select__top {
      margin-bottom: 0px;
    }
    .vs__dropdown-toggle {
      height: 34px;
    }
  }
  &__loading {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__nav-container {
    width: 60%;
    display: flex;
    align-self: center;
  }
}
</style>
